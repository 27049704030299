<template>
    <div class="page-b2c m-t-72">
        <h1 class="d-none">
            Peekage - Consumer App | Digital Product Sampling Platform
        </h1>
        <div class="section-one">
            <div class="container h-100">
                <div class="d-flex h-75 align-items-center">
                    <div>
                        <h2 class="font roboto-medium f-s-26 slogan">
                            <!-- Influence the Brands You Love <br> Discover and Try New Products for Free With Peekage -->
                            Try New Free Products With Peekage
                        </h2>
                        <!-- <p class="font roboto-bold f-s-45 slogon">Discover<span class="dot red">.</span> Try it<span class="dot red">.</span> Buy it<span class="dot red">.</span></p> -->
                        <p class="font roboto-regular color lighter-gray f-s-18 mb-37 description">
                            Are you interested in a new product—but wanted to try it first? With Peekage,<br> you can. Browse through thousands of products,<br> claim offers, and get them shipped to you for free.<br> We’ll even reward you for voicing your opinion about them.
                        </p>
                        <div class="btn-download-app">
                            <a class="btn btn-download btn-apple mr-3"
                               href="https://apps.apple.com/us/app/peekage/id1480532009?ls=1"
                               @click="sendEvent('App Store - Main Banner')"
                            />
                            <a class="btn btn-download btn-google-play"
                               href="https://play.google.com/store/apps/details?id=com.peekage.peekage"
                               @click="sendEvent('Play Store - Main Banner')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-two">
            <center class="section-header">
                <h3 class="font roboto-regular f-s-32">
                    What Peekage Offers
                </h3>
            </center>

            <div class="container">
                <div class="row card-large">
                    <div class="col-md-6 text-center">
                        <img class="w-100" :src="$image('b2c/img-1.svg')" style="height: 391px">
                    </div>
                    <div class="col-md-6">
                        <h4 class="font roboto-regular color red-default header f-s-28">
                            Choose from thousands of exciting <br> products.
                        </h4>
                        <p class="font roboto-regular description f-s-18">
                            Sample products you’ve always wanted to try—for free! <br> Discover exciting offers from our diverse catalog,<br> whether it’s the best in health and beauty or a new <br> organic baby food.
                        </p>
                    </div>
                </div>
                <div class="row card-large reverse">
                    <div class="col-md-6">
                        <h4 class="font roboto-regular color red-default header f-s-28">
                            Try them first, so you can buy with<br> confidence.
                        </h4>
                        <p class="font roboto-regular description f-s-18">
                            Just claim an offer, fill in your address, and we’ll send it <br> to you for free.
                        </p>
                    </div>
                    <div class="col-md-6 text-center">
                        <img class="w-100" :src="$image('b2c/img-2.svg')" style="height: 372px">
                    </div>
                </div>
                <div class="row card-large">
                    <div class="col-md-6 text-center">
                        <img class="w-100" :src="$image('b2c/img-3.svg')" style="height: 342px">
                    </div>
                    <div class="col-md-6">
                        <h4 class="font roboto-regular color red-default header f-s-28">
                            Get rewarded for telling brands what <br> you really think.
                        </h4>
                        <p class="font roboto-regular description f-s-18">
                            Did you like one of the products you tested? Voice your opinion directly with your favorite brands brands through our PACKS—and get rewarded with coins!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="section section-three">
            <center class="section-header">
                <h3 class="font roboto-regular f-s-32">
                    How Peekage Works
                </h3>
            </center>

            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card border-0">
                                    <h4 class="font roboto-medium f-s-18 header">
                                        Create and Personalize Your Profile
                                    </h4>
                                    <p class="font roboto-regular f-s-14 color muted">
                                        Help us get to know you better by answering simple questions that we’ll use to send you personalized offers for your products you’ll like.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card border-0">
                                    <h4 class="font roboto-medium f-s-18 header">
                                        Browse Hundreds of Products
                                    </h4>
                                    <p class="font roboto-regular f-s-14 color muted">
                                        Check out our robust catalog of products from your favorite brands. Find and try new products without spending anything.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card border-0">
                                    <h4 class="font roboto-medium f-s-18 header">
                                        Claim Free Samples
                                    </h4>
                                    <p class="font roboto-regular f-s-14 color muted">
                                        See something you like? Claim the offer, provide us your shipping details, and we’ll ship the product directly to you. It’s that easy!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <center>
                            <div class="device-frame slider"
                                 ref="slider"
                                 :style="{backgroundImage: `url(${$image(currentImage)})`}"
                            />
                        </center>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card border-0">
                                    <h4 class="font roboto-medium f-s-18 header">
                                        Answer Packs, Receive Coins
                                    </h4>
                                    <p class="font roboto-regular f-s-14 color muted">
                                        Earn additional coins on Peekage by answering your Packs. We reward you for providing valuable feedback on the products you try.
                                    </p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="card border-0">
                                    <h4 class="font roboto-medium f-s-18 header">
                                        Redeem Coins for New Products and Gift Cards
                                    </h4>
                                    <p class="font roboto-regular f-s-14 color muted">
                                        You can redeem your coins to try exciting new products or for gift cards from your favorite brands.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-five">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <center>
                            <div class="device-frame screen" :style="{ 'background-image': `url(${$image('b2c/device-img/screen_1.png')})` }" />
                        </center>
                    </div>
                    <div class="col-md-7 py-5 px-5">
                        <h5>Get the app now!</h5>
                        <div class="btn-download-app mt-5">
                            <a hreflang="en-ca" class="btn btn-download btn-apple mr-3"
                               href="https://apps.apple.com/us/app/peekage/id1480532009?ls=1"
                               @click="sendEvent('App Store - Down')"
                            />
                            <a hreflang="en-ca" class="btn btn-download btn-google-play"
                               href="https://play.google.com/store/apps/details?id=com.peekage.peekage"
                               @click="sendEvent('Play Store - Down')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { breadcrumb } from '~/scripts/utils/constants/structured-data/index';

export default {
    layout: 'theme',
    data() {
        return {
            slider: {
                active: 0,
                images: [
                    'b2c/device-img/screen_1.png',
                    'b2c/device-img/screen_2.png',
                    'b2c/device-img/screen_3.png',
                    'b2c/device-img/screen_4.png',
                    'b2c/device-img/screen_5.png',
                ],
            },
        };
    },
    head() {
        return {
            title: 'Peekage App: Free Product Samples & Rewards Program',
            __dangerouslyDisableSanitizers: ['script'],
            script: [
                {
                    innerHTML: JSON.stringify(breadcrumb({
                        name: 'Consumer APPs',
                        uri: this.$route.path,
                    })),
                    type: 'application/ld+json',
                },
            ],
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: 'Download the Peekage app to receive and evaluate free product samples. It’s simple- you review and we reward. Shipping’s on us too!',
                },
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://peekage.com/consumer-apps',
                },
            ],
        };
    },
    computed: {
        currentImage () {
            const slider = this.slider;
            return slider.images[slider.active];
        },
    },
    mounted() {
        this.$setInterval(() => {
            if (this.slider.active < 4) {
                ++this.slider.active;
            } else {
                this.slider.active = 0;
            }
        }, 5000);
    },
    methods: {
        submit() {
            this.$gtm.sendGTMEvent({
                event: 'event',
                eventCategory: 'Consumer Apps',
                eventAction: 'Click',
                eventLabel: 'Subscribe',
            });
        },
        sendEvent(eventLabel) {
            this.$gtm.sendGTMEvent({
                event: 'event',
                eventCategory: 'Consumer Apps',
                eventAction: 'Download App',
                eventLabel: eventLabel,
            });
        },

    },
};
</script>

<style lang="scss">
    .page-b2c {

        .device-frame {
            width: 100%;
            max-width: 280px;
            height: 524px;
            background-color: #c1c9d1;
            border-radius: 30px;
            border: 15px solid #ebeff4;
            position: relative;
            overflow: hidden;
            background-size: contain;
            &.screen {
                background-size: cover;
            }
            &.slider {
                transition: all 1s;
            }
            &::before {
                content: "";
                position: absolute;
                top: -12px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #ebeff4;
                width: 150px;
                height: 30px;
                border-radius: 50px;
            }

            &::after {
                content: "";
                width: 40px;
                height:4px;
                background-color:#c1c9d1;
                position: absolute;
                top: 5px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 5px;
            }
        }

        .section-one {
            height: calc(630px + 160px );
            margin-top: 50px;
            position: relative;
            overflow-x: hidden;
            background-image: url("./../assets/images/b2c/screens.svg");
            background-repeat: no-repeat;
            background-position: right;
            @media only screen and (min-width: 600px) and (max-width: 768px){
                background-image: unset;
            }

            @media only screen and (min-width: 1200px) {
                background-size: 50% !important;
            }

            @media only screen and (min-width: 992px) {
                background-size: 35% !important;
            }

            @media only screen and (min-width: 768px) {
                background-size: 35% !important;
            }

            @media only screen and (max-width: 600px) {
                background-image: unset;
                margin-top: 0px;
                margin-bottom: 10px;
                padding: 100px 0px;
                height: auto;
                // background-image: linear-gradient(to bottom, #ff7b71, #ff3c55 118%);
                // color: white;
                .slogan {
                    font-size: 20px !important;
                }
                .description {
                    margin-top: 50px;
                    color: inherit;
                }

                .btn-download-app {
                    padding-top: 66px;
                    .btn.btn-normal {
                        width: 180px;
                        background-size: contain;
                    }
                    .btn-apple {
                        margin-right: 0px !important;
                    }
                }
            }

            .dot {
                &.red {
                    color: #f38580;
                }
            }

            .slogon {
                line-height: 1.38;
                letter-spacing: -0.4px;
            }

            .btn-download-app {
                padding-top: 66px;
                .btn-apple {
                    margin-right: 20px;
                }
            }
        }

        .section-two {
            padding-top: 222px;
            background-image: url("./../assets/images/b2c/graphic-1.svg");
            background-repeat: no-repeat;
            background-position: top left;
            @media only screen and (max-width: 600px) {
                padding-top: 250px;

            }
            .section-header {
                margin-bottom: 83px;
            }

            .card-large {
                margin-bottom: 60px;
                .header {
                    line-height: 1.43;
                    letter-spacing: -0.18px;
                }

                .description {
                    line-height: 1.56;
                }

                .image {
                    background-image: linear-gradient(207deg, #ebeff5 35%, #c3cedd -79%);
                    width: 100%;
                    height: 258px;
                    border-radius: 18px;
                    margin-bottom: 10px;
                }

                @media only screen and (min-width: 600px) and (max-width: 768px){
                    &.reverse {
                        flex-direction: column-reverse;
                    }
                }

                @media only screen and (max-width: 600px){
                    &.reverse {
                        flex-direction: column-reverse;
                    }
                }

            }
        }

        .section-three {
            background-image: url("./../assets/images/b2c/graphic-2.svg");
            background-repeat: no-repeat;
            background-position: top right;

            .section-header {
                margin-bottom: 66px;
                padding-top: 180px;
            }

            .card {
                padding: 24px;
                margin-bottom: 50px;
                &:hover {
                    border-radius: 4px;
                    box-shadow: 0 0 24px 0 rgba(141, 141, 141, 0.09);
                }
                .header {
                    line-height: 1.56;
                }
            }
        }

        .section-four {
            //
            margin-top: 80px;
            .card {
                box-shadow: 0 0 24px 0 rgba(141, 141, 141, 0.09);
                padding: 105px 67px;
                position: relative;
                &::before {
                    content: "";
                    background-image: url("./../assets/images/b2c/graphiac-5.svg");
                    background-repeat: no-repeat;
                    background-position: right;
                    height: 100%;
                    width: 300px;
                    position: absolute;
                    right: 72px;
                    top: 50%;
                    transform: translateY(-50%);

                }

                @media only screen and (max-width: 600px) {
                    box-shadow: unset;
                    padding: 0 10px;
                    &::before {
                        display: none;
                    }
                    .form-subscribe {
                        margin-top: 50px;
                        overflow: hidden;
                        input[type=email] {
                            width: 100%;
                            padding: 11px 0 15px 0;
                            border-bottom: 1px solid #999999 !important;
                            margin-bottom: 24px;
                        }
                    }
                }
            }

            .form-subscribe {
                margin-top: 50px;
                input[type=email] {
                    width: 290px;
                    padding: 11px 0 15px 0;
                    border-bottom: 1px solid #999999 !important;
                    margin-right: 24px;
                }
            }
        }

        .section-five {
            margin-top: 200px;
            .device-frame {
                border-bottom: unset;
                border-bottom-right-radius: unset;
                border-bottom-left-radius: unset;
                height: 350px
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .footer-home {
            .extra {
                margin-top: 0px !important;
            }
        }
    }
</style>
